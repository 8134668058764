import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Global} from '../models/global';

@Injectable({providedIn: 'root'})
export class AdminAuthGuard implements CanActivate {
    constructor(private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        // check if admin user is already logged in
        if (Global.getAdminUser()) {
            return true;
        }
        this.router.navigate(['/login']);
        return false;
    }
}
