import {Component} from '@angular/core';
import {LanguageService} from './services/language.service';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  constructor(private languageService: LanguageService, private titleService: Title) {
      titleService.setTitle(this.languageService.languageMap.label);
  }
}
