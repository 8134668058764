import {Component, OnInit, ViewChild} from '@angular/core';
import {SignaturePad} from 'angular2-signaturepad/signature-pad';
import {ActivatedRoute, Router} from '@angular/router';
import {DataService} from '../services/data.service';
import {BriefingStep, Global} from '../models/global';
import {Approval} from '../models/approval';
import {BehaviorSubject} from 'rxjs';
import {LanguageService} from '../services/language.service';

@Component({
    selector: 'app-approval',
    templateUrl: './approval.component.html',
    styleUrls: ['./approval.component.css']
})
export class ApprovalComponent implements OnInit {

    private approval: Approval;
    private videoOnly = false;

    // @ts-ignore
    @ViewChild(SignaturePad) signaturePad: SignaturePad;

    private signaturePadOptions: object = { // passed through to szimek/signature_pad constructor
        minWidth: 1,
        canvasWidth: 700,
        canvasHeight: 300
    };

    constructor(private dataService: DataService, private router: Router, private languageService: LanguageService) {
        if (Global.getVideoOnly() === 'true') {
            this.videoOnly = true;
        }
        this.dataService.getApproval().subscribe(data => {
            const currentUserSubject = new BehaviorSubject<Approval>(data);
            this.approval = currentUserSubject.value;
            if (this.approval.positivePercentage > this.approval.correctAnswers / this.approval.totalAnswers) {
                Global.setBriefingStep(BriefingStep.ApprovalNegative);
                router.navigate(['/approval/negative']);
            }
            this.initSign();
        });
    }

    // tslint:disable-next-line:use-lifecycle-interface
    ngAfterViewInit() {
        // this.signaturePad is now available

    }

    initSign() {
        // this.signaturePad.set('minWidth', 1); // set szimek/signature_pad options at runtime
        // this.signaturePad.clear(); // invoke functions from szimek/signature_pad API
    }

    drawComplete() {
        // will be notified of szimek/signature_pad's onEnd event
        this.approval.signature = this.signaturePad.toDataURL();
    }

    drawStart() {
        // will be notified of szimek/signature_pad's onBegin event

    }

    saveSignature() {
        const approval = this.approval;
        this.dataService.createApproval(approval).subscribe(data => {
            approval.id = data.id;
            this.dataService.createApprovalPDF(approval).subscribe(() => {
                Global.setBriefingStep(BriefingStep.Completed);
                this.router.navigate(['/completed']);
            });
        });
    }
    clearSignature(){
        this.signaturePad.clear();
    }


    ngOnInit(): void {
    }

}
