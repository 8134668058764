import {Component, OnInit} from '@angular/core';
import {DataService} from '../services/data.service';
import {Briefing} from '../models/briefing';
import {Category} from '../models/category';
import {ActivatedRoute, Router} from '@angular/router';
import {BehaviorSubject} from 'rxjs';

@Component({
    selector: 'app-admin-question-categories-edit',
    templateUrl: './admin-questions-categories-edit.component.html',
    styleUrls: ['./admin-questions-categories-edit.component.css']
})
export class AdminQuestionsCategoriesEditComponent implements OnInit {

    errorMessage: string;
    message: string;


    briefing: Briefing;
    briefings: Briefing[];

    category: Category;

    constructor(private dataService: DataService, private router: Router, private route: ActivatedRoute) {
        const briefingId = this.route.snapshot.paramMap.get('briefingId');
        this.dataService.getBriefing(+briefingId).subscribe(data => {
            const currentUserSubject = new BehaviorSubject<Briefing>(data);
            this.briefing = currentUserSubject.value;
        });
        this.dataService.getBriefings().subscribe(data => {
            const currentUserSubject = new BehaviorSubject<Briefing[]>(data);
            this.briefings = currentUserSubject.value;
        });
        const categoryId = this.route.snapshot.paramMap.get('categoryId');
        this.dataService.getCategory(+categoryId).subscribe(data => {
            const currentUserSubject = new BehaviorSubject<Category>(data);
            this.category = currentUserSubject.value;
        });
    }

    get briefingId(): number {
        return this.briefing.id;
    }

    set briefingId(id: number) {
        this.dataService.getBriefing(id).subscribe(data => {
            const currentUserSubject = new BehaviorSubject<Briefing>(data);
            this.briefing = currentUserSubject.value;
        });
        this.category.briefingId = id;
    }

    ngOnInit() {
    }

    update() {
        if (!this.category.label || this.category.label.length === 0) {
            this.errorMessage = 'Bitte überprüpfen Sie Ihre Eingaben.';
        } else {
            this.dataService.updateCategory(this.category).subscribe(data => {
                this.router.navigate(['/admin/questions/categories/' + this.briefing.id]);
            });
        }
    }

}
