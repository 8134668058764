import { Component, OnInit } from '@angular/core';
import { Global } from '../models/global';
import {LoaderService} from '../services/loader.service';
import {ActivatedRoute, Router, RouterEvent} from '@angular/router';
import {LanguageService} from "../services/language.service";

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {

  isLandingPage = false;

  constructor(private loaderService: LoaderService, private router: Router, private languageService: LanguageService) {
    router.events.subscribe((url) => {
      if (url instanceof RouterEvent && url.url != null) {
        if (url.url === '/' || url.url === '/welcome') {
          this.isLandingPage = true;
        } else {
          this.isLandingPage = false;
        }
      }
    });
  }

  ngOnInit() {
  }

  public logoutAdminUser() {
    Global.logoutAdminUser();
  }

  isAdminLoggedIn(): boolean {
    return Global.getAdminUser() != null;
  }

  public cancelBriefing() {
    Global.logoutUser();
  }

  isBriefingRunning(): boolean {
    return Global.getUser() != null;
  }

}
