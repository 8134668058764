import {Component, OnInit} from '@angular/core';
import {Global} from '../models/global';
import {DataService} from '../services/data.service';
import {LanguageService} from '../services/language.service';

@Component({
    selector: 'app-completed',
    templateUrl: './completed.component.html',
    styleUrls: ['./completed.component.css']
})
export class CompletedComponent implements OnInit {

    id;

    constructor(private dataService: DataService, private languageService: LanguageService) {
        this.dataService.getApproval().subscribe(data => {
            this.id = data.briefingId;
            Global.logoutUser();
        });
    }

    ngOnInit() {

    }

}
