import {Component, OnInit} from '@angular/core';
import {User} from '../models/user';
import {BriefingStep, Global} from '../models/global';
import {ActivatedRoute, Router} from '@angular/router';
import {DataService} from '../services/data.service';
import {Briefing} from '../models/briefing';
import {BehaviorSubject} from 'rxjs';
import {MarkdownService} from 'ngx-markdown';
import {LanguageService} from '../services/language.service';


@Component({
    selector: 'app-briefing-markdown',
    templateUrl: './briefing-markdown.component.html',
    styleUrls: ['./briefing-markdown.component.css']
})
export class BriefingMarkdownComponent implements OnInit {

    briefing: Briefing;

    user: User;
    markdown: string;

    step = +this.route.snapshot.paramMap.get('step');

    // tslint:disable-next-line:max-line-length
    constructor(private dataService: DataService, private router: Router, private route: ActivatedRoute, private markdownService: MarkdownService, private languageService: LanguageService) {
        this.user = Global.getUser();
    }

    ngOnInit() {
        this.dataService.getCurrentUserBriefing().subscribe(data => {
            const currentUserSubject = new BehaviorSubject<Briefing>(data);
            this.briefing = currentUserSubject.value;
            if (this.briefing.videoUrl) {
                this.router.navigate(['/briefing']);
            } else {
                if (this.step >= this.briefing.markdown.length) {
                    this.router.navigate(['/briefing/md']);
                    this.step = 0;
                }

                this.markdown = this.markdownService.compile(this.briefing.markdown[this.step]);
            }
        });
    }

    briefingFinished() {
        Global.setBriefingStep(BriefingStep.Questions);
    }


    isBriefingFinished(): boolean {
        return (this.step >= this.briefing.markdown.length - 1);
    }

    nextStep() {
        this.router.navigate(['/briefing/md/' + (++this.step)]);
        this.markdown = this.markdownService.compile(this.briefing.markdown[this.step]);
        console.log(this.markdown);
    }
}




