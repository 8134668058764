import {Component, OnInit, ViewChild} from '@angular/core';
import {User} from '../models/user';
import {BriefingStep, Global} from '../models/global';
import {Router} from '@angular/router';
import {DataService} from '../services/data.service';
import {Briefing} from '../models/briefing';
import {BehaviorSubject} from 'rxjs';
import {YoutubePlayerService} from '../services/youtube-player.service';
import {LanguageService} from '../services/language.service';
import {SwiperComponent} from 'ngx-useful-swiper';
import {SwiperOptions} from 'swiper';

@Component({
    selector: 'app-briefing',
    templateUrl: './briefing.component.html',
    styleUrls: ['./briefing.component.css']
})
export class BriefingComponent implements OnInit {
    @ViewChild('usefulSwiper', {static: false}) usefulSwiper: SwiperComponent;

    config: SwiperOptions = {
        pagination: {el: '.swiper-pagination', clickable: false},
        autoHeight: true,
        allowTouchMove: false,
        slidesPerView: 1,
    };

    imageObject: Array<object> = [];

    briefing: Briefing;

    user: User;
    onlyVideo = false;
    videoFinished = false;
    imageSlider = false;
    sliderFinished = false;
    currentSlide = 0;
    totalSlides = 0;

    constructor(private dataService: DataService, private router: Router, private youtubePlayer: YoutubePlayerService, private languageService: LanguageService) {
        this.user = Global.getUser();
    }

    ngOnInit() {
        const doc = window.document;
        const playerApi = doc.createElement('script');
        playerApi.type = 'text/javascript';
        playerApi.src = 'https://www.youtube.com/iframe_api';
        doc.body.appendChild(playerApi);

        this.dataService.getCurrentUserBriefing().subscribe(data => {
            const currentUserSubject = new BehaviorSubject<Briefing>(data);
            this.briefing = currentUserSubject.value;
            if (this.briefing.presentationImages) {
                this.imageSlider = true;
                this.totalSlides = this.briefing.presentationImages.length;
                for (var i = 0; this.briefing.presentationImages.length > i; i++) {
                    let tempObject = {
                        image: this.briefing.presentationImages[i],
                        title: 'Image ' + i
                    };
                    this.imageObject.push(tempObject);
                }
            } else {
                if (this.briefing.videoUrl) {
                    this.youtubePlayer.createPlayer(this.briefing.videoUrl);
                } else {
                    this.router.navigate(['/briefing/md']);
                }
            }
            if (this.briefing.questionCount == 0) {
                this.dataService.isLastQuestion().subscribe();
                this.onlyVideo = true;
                Global.setVideoOnly(this.onlyVideo);
            }
        });
    }

    briefingFinished() {
        if (this.isVideoFinished()) {
            this.youtubePlayer.ngOnDestroy();
            Global.setBriefingStep(BriefingStep.Questions);
        }
        if (this.sliderFinished) {
            Global.setBriefingStep(BriefingStep.Questions);
        }
    }

    nextSlide() {
        this.usefulSwiper.swiper.slideNext();
        this.currentSlide = this.usefulSwiper.swiper.activeIndex;
        if (this.currentSlide == this.totalSlides - 1) {
            this.sliderFinished = true;
        }
    }

    previousSlide() {
        this.usefulSwiper.swiper.slidePrev();
        this.currentSlide = this.usefulSwiper.swiper.activeIndex;
    }

    slideToThis(index) {
        this.usefulSwiper.swiper.slideTo(index);
    }

    isVideoFinished(): boolean {
        if (this.youtubePlayer) {
            return this.videoFinished = this.videoFinished || this.youtubePlayer.isFinished();
        } else {
            return this.videoFinished;
        }
    }
}




