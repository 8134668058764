import {Component, OnInit} from '@angular/core';
import {AuthorizedUser} from '../models/authorized-user';
import {DataService} from '../services/data.service';
import {BehaviorSubject} from 'rxjs';
import {Approval} from "../models/approval";
import {Question} from "../models/question";

@Component({
    selector: 'app-admin-evaluation',
    templateUrl: './admin-evaluation.component.html',
    styleUrls: ['./admin-evaluation.component.css']
})
export class AdminEvaluationComponent implements OnInit {

    allAuthorizedUsers: AuthorizedUser[];
    authorizedUsers: AuthorizedUser[];

    // tslint:disable-next-line:variable-name
    private _search: string;

    constructor(private dataService: DataService) {
        this.loadApprovals();
    }

    ngOnInit() {
    }

    get search() {
        return this._search;
    }

    set search(value: string) {
        this._search = value;
        this.authorizedUsers = this.allAuthorizedUsers.filter(au => {
            let found = true;

            const approvalMatchesSearch = this.checkApprovalOfUser(this.search, au);
            this.search.split(' ').forEach(filter => {
                filter = filter.toLowerCase();
                // tslint:disable-next-line:max-line-length
                if (!(au.firstName.toLowerCase().indexOf(filter) >= 0 || au.lastName.toLowerCase().indexOf(filter) >= 0 || au.email.toLowerCase().indexOf(filter) >= 0 || au.company.toLowerCase().indexOf(filter) >= 0) && !approvalMatchesSearch) {
                    found = false;
                }
            });

            return found;
        });
    }

    private checkApprovalOfUser(search: string, au: AuthorizedUser): boolean {
        let found = false;
        search.split(' ').forEach(filter => {
            filter = filter.toLowerCase();
            au.approvals.forEach(approval => {
                if (approval.briefingLabel.toLowerCase().indexOf(filter) >= 0) {
                    found = true;
                }
            });
        });
        return found;
    }

    public deleteApproval(approval: Approval){
        if (confirm('Möchten Sie die Unterweisung ' + approval.briefingLabel + ' wirklich löschen?').valueOf()) {
            this.dataService.deleteApproval(approval).subscribe(data => {
                this.loadApprovals();
            });
        }
    }

    private loadApprovals(){
        this.dataService.getAuthorizedUsers().subscribe(data => {
            const currentUserSubject = new BehaviorSubject<AuthorizedUser[]>(data);
            this.allAuthorizedUsers = currentUserSubject.value;
            this.authorizedUsers = currentUserSubject.value;
        });
    }

}
