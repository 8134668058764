import {EventEmitter, Injectable, OnDestroy, Output} from '@angular/core';

// import {Observable} from 'rxjs';
/*import {NotificationService} from './notification.service';
import {BrowserNotificationService} from './browser-notification.service';*/

// tslint:disable-next-line:variable-name
const _window: any = window;

@Injectable()
export class YoutubePlayerService implements OnDestroy {
    public ytPlayer;
    private currentVideoId: string;
    private interval;

    @Output() videoChangeEvent: EventEmitter<any> = new EventEmitter(true);
    @Output() playPauseEvent: EventEmitter<any> = new EventEmitter(true);
    @Output() currentVideoText: EventEmitter<any> = new EventEmitter(true);

    constructor() {
    }

    createPlayer(videoId: string): void {
        this.interval = setInterval(() => {
            if ((typeof _window.YT !== 'undefined') && _window.YT && _window.YT.Player) {
                this.ytPlayer = new _window.YT.Player('yt-player', {
                    width: '800',
                    height: '523',
                    playerVars: {
                        autoplay: 1,
                        controls: 0,
                        iv_load_policy: '3',
                        rel: '0'
                    },
                    videoId,
                    events: {
                        onStateChange: () => {

                        }
                    }
                });
                clearInterval(this.interval);
            }

        }, 100);

    }

    onPlayerStateChange(event: any) {
        /*console.log(this.ytPlayer.getCurrentTime());
        console.log(this.ytPlayer.getDuration());*/

        /*
          getApiInterface()
          getCurrentTime()
          getDuration()
          getPlayerState()
          showVideoInfo
        */

        const state = event.data;
        switch (state) {
            case 0:
                this.videoChangeEvent.emit(true);
                this.playPauseEvent.emit('pause');
                break;
            case 1:
                this.playPauseEvent.emit('play');
                break;
            case 2:
                this.playPauseEvent.emit('pause');
                break;
        }
    }

    playVideo(videoId: string, videoText?: string): void {
        if (!this.ytPlayer) {
            // this.notificationService.showNotification('Player not ready.');
            return;
        }
        this.ytPlayer.loadVideoById(videoId);
        this.currentVideoId = videoId;
        this.currentVideoText.emit(videoText);
        // this.browserNotification.show(videoText);
    }

    pausePlayingVideo(): void {
        this.ytPlayer.pauseVideo();
    }

    playPausedVideo(): void {
        this.ytPlayer.playVideo();
    }

    getCurrentVideo(): string {
        return this.currentVideoId;
    }

    resizePlayer(width: number, height: number) {
        this.ytPlayer.setSize(width, height);
    }

    getShuffled(index: number, max: number): number {
        if (max < 2) {
            return;
        }

        const i = Math.floor(Math.random() * max);
        return i !== index ? i : this.getShuffled(index, max);
    }

    isFinished(): boolean {
        if (this.ytPlayer) {
            if (this.ytPlayer.getDuration !== undefined && this.ytPlayer.getDuration() - this.ytPlayer.getCurrentTime() < 10 && this.ytPlayer.getDuration() > 0) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    public ngOnDestroy(): void {
        this.ytPlayer.stopVideo();
        this.ytPlayer.clearVideo();
        this.ytPlayer.unloadModule();
        /*this.ytPlayer.ngOnDestroy();*/
        this.videoChangeEvent.unsubscribe();
        this.playPauseEvent.unsubscribe();
        this.currentVideoText.unsubscribe();
        this.ytPlayer = null;
    }
}
