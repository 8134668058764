import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {User} from '../models/user';
import {BriefingStep, Global} from '../models/global';
import {DataService} from '../services/data.service';
import {LanguageService} from '../services/language.service';

@Component({
    selector: 'app-welcome',
    templateUrl: './welcome.component.html',
    styleUrls: ['./welcome.component.css']
})


export class WelcomeComponent implements OnInit {

    user: User;

    errorMessage = '';
    isExtern = true;
    showForm = false;
    personForm;
    dsgvoAccepted: boolean;
    label: string;
    labelText: string;
    uid;

    createUser(form) {
        if (form.valid) {
            this.errorMessage = '';

            this.dataService.createUser(this.user).subscribe(data => {
                this.user.userId = +data.id;
                Global.setApiToken(data.token);
                Global.setUser(this.user);
                this.dataService.startBriefing(+this.route.snapshot.paramMap.get('userType'), Global.getUser()).subscribe(d => {
                    Global.setBriefingStep(BriefingStep.Briefing);
                    this.router.navigate(['/briefing']);
                });
            });
        } else {
            this.errorMessage = this.languageService.languageMap.welcomeFormError;
        }

    }

    constructor(private dataService: DataService, private router: Router, private route: ActivatedRoute, private languageService: LanguageService) {
        this.user = new User();
        Global.setVideoOnly(false);
        this.user.userType = +this.route.snapshot.paramMap.get('userType');
        const typeArray = [+languageService.languageMap.baseId , +languageService.languageMap.serviceId, +languageService.languageMap.officeId, +languageService.languageMap.maintenanceId];
        if(typeArray.includes(this.user.userType)){
            this.showForm=true;
            this.isExtern = this.user.userType === +languageService.languageMap.baseId || this.user.userType === +languageService.languageMap.serviceId;
        }
        this.dataService.getBriefingClient(this.user.userType).subscribe(data => {
            this.label = data.label;
            this.uid = data.uid;

            if(this.user.userType === +languageService.languageMap.baseId || this.user.userType === +languageService.languageMap.serviceId){
                this.labelText = languageService.languageMap.visitor;
            }else{
                this.labelText = languageService.languageMap.employee;
            }
            switch (this.user.userType) {
                case +languageService.languageMap.baseId:
                    this.labelText += ' '+languageService.languageMap.base;
                    break;
                case +languageService.languageMap.serviceId:
                    this.labelText += ' '+languageService.languageMap.service;
                    break;
                case +languageService.languageMap.officeId:
                    this.labelText += ' '+languageService.languageMap.office;
                    break;
                case +languageService.languageMap.maintenanceId:
                    this.labelText += ' '+languageService.languageMap.maintenance;
                    break;
                default:
                    this.labelText += '';
            }

        });
    }

    ngOnInit() {

    }


}
