import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
@Injectable()
export class LoaderService {
    isLoading = false;
    stack = 0;
    show() {
        this.stack++;
        this.reload();
    }
    hide() {
        this.stack--;
        this.reload();
    }
    reload() {
        this.isLoading = this.stack > 0;
        this.stack = this.stack >= 0 ? this.stack : 0;
    }
}
