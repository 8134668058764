import {Component, OnInit} from '@angular/core';
import {Question} from '../models/question';
import {DataService} from '../services/data.service';
import {BriefingStep, Global} from '../models/global';
import {BehaviorSubject} from 'rxjs';
import {Router} from '@angular/router';
import {LanguageService} from '../services/language.service';
import {Briefing} from "../models/briefing";

@Component({
    selector: 'app-question',
    templateUrl: './question.component.html',
    styleUrls: ['./question.component.css']
})
export class QuestionComponent implements OnInit {

    public question: Question;

    constructor(private dataService: DataService, private router: Router, private languageService: LanguageService) {
        this.dataService.getQuestion().subscribe(data => {
            const currentUserSubject = new BehaviorSubject<Question>(data);
            this.question = currentUserSubject.value;
            if (this.question.finished) {
                Global.setBriefingStep(BriefingStep.Approval);
                this.router.navigate(['/approval']);
            }
        });
        this.dataService.getCurrentUserBriefing().subscribe(data => {
            const currentUserSubject = new BehaviorSubject<Briefing>(data);
            if (currentUserSubject.value.questionCount == 0) {
                this.router.navigate(['/approval']);
            }
        });
    }

    ngOnInit() {

    }

    submit() {
        this.dataService.submitQuestion(this.question).subscribe(data => {
            this.dataService.isLastQuestion().subscribe(last => {
                if (last.toString() === 'true') {
                    Global.setBriefingStep(BriefingStep.Approval);
                    this.router.navigate(['/approval']);
                } else {
                    this.dataService.getQuestion().subscribe(d => {
                        const currentUserSubject = new BehaviorSubject<Question>(d);
                        this.question = currentUserSubject.value;
                        if (this.question.finished) {
                            Global.setBriefingStep(BriefingStep.Approval);
                            this.router.navigate(['/approval']);
                        }
                    });
                }
            });
        });
    }

    isAnswerChecked() {
        let checked = false;

        if (this.question != null) {
            this.question.answers.forEach(answer => {
                if (answer.selected) {
                    checked = true;
                }
            });
        }
        return checked;
    }

}
