import {Component, OnInit} from '@angular/core';
import {DataService} from '../services/data.service';
import {AdminUser} from '../models/admin-user';
import {BehaviorSubject} from 'rxjs';
import {Settings} from '../models/settings';

@Component({
    selector: 'app-admin-users',
    templateUrl: './admin-settings.component.html',
    styleUrls: ['./admin-settings.component.css']
})
export class AdminSettingsComponent implements OnInit {

    adminUsers: AdminUser[];
    changed: boolean;

    settings: Settings;

    constructor(private dataService: DataService) {
        this.dataService.getAdminUsers().subscribe(data => {
            const currentUserSubject = new BehaviorSubject<AdminUser[]>(data);
            this.adminUsers = currentUserSubject.value;
        });
        this.dataService.getSettings().subscribe(data => {
            const currentUserSubject = new BehaviorSubject<Settings>(data);
            this.settings = currentUserSubject.value;
        });
    }

    ngOnInit() {
    }

    delete(user: AdminUser) {
        if (confirm('Möchten Sie den Benutzer ' + user.username + ' wirklich löschen?')) {
            this.dataService.deleteAdminUser(user).subscribe(() => {
                this.dataService.getAdminUsers().subscribe(data => {
                    const currentUserSubject = new BehaviorSubject<AdminUser[]>(data);
                    this.adminUsers = currentUserSubject.value;
                });
            });
        }
    }

    update() {
        this.dataService.updateSettings(this.settings).subscribe(() => {
            this.changed = false;
        });
    }
}
