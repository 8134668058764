import {Answer} from './answer';
import {Category} from './category';

export class Question {
    id: number;

    label: string;

    description: string;

    answers: Answer[];

    category: Category;

    finished = false;

    constructor(id?: number, label?: string, description?: string, category?: Category, answers?: Answer[]) {
        this.id = id;
        this.label = label;
        this.description = description;
        this.answers = answers;
        this.category = category;
    }

}
