import {Component, OnInit} from '@angular/core';
import {DataService} from '../services/data.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Briefing} from '../models/briefing';
import {BehaviorSubject} from 'rxjs';

@Component({
    selector: 'app-admin-questions-settings-edit',
    templateUrl: './admin-questions-settings-edit.component.html',
    styleUrls: ['./admin-questions-settings-edit.component.css']
})
export class AdminQuestionsSettingsEditComponent implements OnInit {

    errorMessage: string;
    message: string;
    briefing: Briefing;
    oldVideoUrl: string;
    oldMarkdown: string[];
    fileName: string;

    uploadPowerpoint = true;
    // tslint:disable-next-line:variable-name
    private _markdownFile;
    fileUploading = false;

    get markdownFile() {
        return this._markdownFile;
    }

    set markdownFile(value) {
        console.log(value);
        this._markdownFile = value;
    }

    constructor(private dataService: DataService, private router: Router, private route: ActivatedRoute) {
        const briefingId = this.route.snapshot.paramMap.get('briefingId');
        this.dataService.getBriefing(+briefingId).subscribe(data => {
            console.log(data);
            const currentUserSubject = new BehaviorSubject<Briefing>(data);
            this.briefing = currentUserSubject.value;
            console.log(this.briefing);
            if (this.briefing && this.briefing.presentation) {
                const presentationName = this.briefing.presentation.toString();
                this.fileName = presentationName.split("/").pop();
                this.uploadPowerpoint = false;
            }
        });
    }

    ngOnInit() {
    }

    update() {
        // tslint:disable-next-line:max-line-length
        if (this.briefing.label.length === 0 || (this.briefing.videoUrl == null && this.briefing.markdown == null) || this.briefing.questionCount < 0 || this.briefing.positiveQuantifier < 0 || this.briefing.positiveQuantifier > 1) {
            this.errorMessage = 'Bitte überprüfen Sie Ihre Eingaben.';
        } else {
            this.dataService.updateBriefing(this.briefing).subscribe(() => {
                this.router.navigate(['/admin/questions/settings']);
            });
        }
    }

    get positiveQuantifier() {
        return this.briefing.positiveQuantifier * 100;
    }

    set positiveQuantifier(value) {
        this.briefing.positiveQuantifier = value / 100;
    }

    selectMarkdown() {
        if (this.oldMarkdown != null) {
            this.briefing.markdown = this.oldMarkdown;
        } else {
            this.briefing.markdown = [''];
        }
        this.oldVideoUrl = this.briefing.videoUrl;
        this.briefing.videoUrl = null;
    }

    selectYoutube() {
        if (this.oldVideoUrl != null) {
            this.briefing.videoUrl = this.oldVideoUrl;
        } else {
            this.briefing.videoUrl = '';
        }
        this.oldMarkdown = this.briefing.markdown;
        this.briefing.markdown = null;
    }


    fileUpload(files: FileList) {
        this.fileUploading = true;
        const file: File = files[0];
        console.log(file);
        const fr = new FileReader();
        fr.readAsText(file);
        fr.onload = () => {
            const filecontent = fr.result.toString();
            this.briefing.markdown = filecontent.split('\n---');
            this.fileUploading = false;
        };
    }

    fileUploadPresentation(files: FileList) {
        const file: File = files[0];
        if (file) {
            const fr = new FileReader();
            fr.readAsBinaryString(file);
            fr.onload = () => {
                const filecontent = fr.result.toString();
                this.briefing.presentation = {
                    fileName: this.deUmlaut(file.name),
                    fileType: file.type,
                    fileExtension: file.name.slice((file.name.lastIndexOf(".") - 1 >>> 0) + 2),
                    value: btoa(filecontent)
                };
            };
        } else {
            this.briefing.presentation = null;
        }

    }

    deUmlaut(value) {
        value = value.toLowerCase();
        value = value.replace(/ä/g, 'ae');
        value = value.replace(/ö/g, 'oe');
        value = value.replace(/ü/g, 'ue');
        value = value.replace(/ß/g, 'ss');
        value = value.replace(/ /g, '_');
        value = value.replace(/,/g, '');
        value = value.replace(/\(/g, '');
        value = value.replace(/\)/g, '');
        return value;
    }

    changePowerpoint(){
        this.uploadPowerpoint = true;
        this.briefing.presentation = null;
    }
}
