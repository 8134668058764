import {Component, OnInit} from '@angular/core';
import {AdminUser} from '../models/admin-user';
import {ActivatedRoute, Router} from '@angular/router';
import {DataService} from '../services/data.service';
import {BehaviorSubject} from 'rxjs';

@Component({
    selector: 'app-admin-user-edit',
    templateUrl: './admin-user-edit.component.html',
    styleUrls: ['./admin-user-edit.component.css']
})
export class AdminUserEditComponent implements OnInit {

    user: AdminUser;
    errorMessage = '';

    repeatPassword = '';

    constructor(private dataService: DataService, private router: Router, private route: ActivatedRoute) {
        const userId = +this.route.snapshot.paramMap.get('id');

        this.dataService.getAdminUser(userId).subscribe(data => {
            const currentUserSubject = new BehaviorSubject<AdminUser>(data);
            this.user = currentUserSubject.value;
        });
    }

    update() {
        if (this.user.username.length === 0) {
            this.errorMessage = 'Bitte geben Sie einen Benutzernamen an.';
        } else if (this.user.password && this.user.password.length < 8 && this.user.password.length > 0) {
            this.errorMessage = 'Das Passwort muss mindestens 8 Zeichen lang sein.';
            // tslint:disable-next-line:triple-equals
        } else if (this.user.password && this.user.password !== this.repeatPassword) {
            this.errorMessage = 'Die Passwörter stimmen nicht überein.';
        } else {
            this.errorMessage = '';
            this.dataService.updateAdminUser(this.user).subscribe(() => {
                this.router.navigate(['/admin/settings']);
            });
        }
    }

    ngOnInit() {
    }

}
