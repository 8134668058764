import {Injectable} from '@angular/core';
import {User} from './user';
import {BehaviorSubject} from 'rxjs';
import {AdminUser} from './admin-user';

@Injectable()
export class Global {

    // @ts-ignore
    public static getUser() {
        const currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        return currentUserSubject.value;
    }

    public static setUser(user: User) {
        localStorage.setItem('currentUser', JSON.stringify(user));
    }

    public static getVideoOnly() {
        return localStorage.getItem('videoOnly');
    }
    public static setVideoOnly(videoOnly) {
        localStorage.setItem('videoOnly', videoOnly);
    }
    public static logoutUser() {
        Global.removeApiToken();
        localStorage.removeItem('currentUser');
    }

    public static setBriefingStep(briefingStep: BriefingStep) {
        localStorage.setItem('briefingStep', JSON.stringify(briefingStep));
    }

    public static getBriefingStep(): BriefingStep {
        if ((!localStorage.getItem('briefingStep')) || localStorage.getItem('briefingStep').length === 0) {
            this.setBriefingStep(BriefingStep.Home);
        }
        const currentUserSubject = new BehaviorSubject<BriefingStep>(JSON.parse(localStorage.getItem('briefingStep')));
        return currentUserSubject.value;
    }

    public static getAdminUser(): AdminUser | null {
        const currentUserSubject = new BehaviorSubject<AdminUser>(JSON.parse(localStorage.getItem('adminUser')));
        return currentUserSubject.value;
    }

    public static setAdminUser(adminUser: AdminUser) {
        // remove password from adminUser
        adminUser.password = '';
        localStorage.setItem('adminUser', JSON.stringify(adminUser));
    }

    public static logoutAdminUser() {
        Global.removeAdminApiToken();
        localStorage.removeItem('adminUser');
    }

    public static setApiToken(token) {
        localStorage.setItem('apiToken', token);
    }

    public static getApiToken(): string {
        const currentUserSubject = new BehaviorSubject<string>(localStorage.getItem('apiToken'));
        return currentUserSubject.value;
    }

    public static removeApiToken() {
        localStorage.removeItem('apiToken');
    }

    public static setAdminApiToken(token) {
        localStorage.setItem('adminApiToken', token);
    }

    static getAdminApiToken() {
        const currentUserSubject = new BehaviorSubject<string>(localStorage.getItem('adminApiToken'));
        return currentUserSubject.value;
    }

    public static removeAdminApiToken() {
        localStorage.removeItem('adminApiToken');
    }

    public static setApiUrl(url) {
        localStorage.setItem('apiUrl', url);
    }

    static getApiUrl() {
        const currentUserSubject = new BehaviorSubject<string>(localStorage.getItem('apiUrl'));
        return currentUserSubject.value;
    }

    static getLanguage() {
        const currentUserSubject = new BehaviorSubject<string>(localStorage.getItem('language'));
        return currentUserSubject.value;
    }

    static setLanguage(l) {
        localStorage.setItem('language', l);
    }
}

export enum BriefingStep {
    Home = '/',
    Welcome = '/welcome',
    Briefing = '/briefing',
    Questions = '/question',
    Approval = '/approval',
    ApprovalNegative = '/approval/negative',
    Completed = '/completed'
}
