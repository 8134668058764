import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Question} from '../models/question';
import {DataService} from '../services/data.service';
import {Category} from '../models/category';
import {Briefing} from '../models/briefing';
import {Answer} from '../models/answer';
import {BehaviorSubject} from 'rxjs';

@Component({
    selector: 'app-admin-question-edit',
    templateUrl: './admin-question-edit.component.html',
    styleUrls: ['./admin-question-edit.component.css']
})
export class AdminQuestionEditComponent implements OnInit {

    errorMessage = '';

    question: Question;
    briefing: Briefing;
    briefings: Briefing[];
    categories: Category[];

    newAnswer: Answer = new Answer();

    constructor(private dataService: DataService, private router: Router, private route: ActivatedRoute) {
        this.briefingId = +this.route.snapshot.paramMap.get('briefingId');

        const questionId = +this.route.snapshot.paramMap.get('questionId');
        this.dataService.getQuestion(questionId).subscribe(data => {
            const currentUserSubject1 = new BehaviorSubject<Question>(data);
            this.question = currentUserSubject1.value;
        });


        this.dataService.getBriefings().subscribe(data => {
            const currentUserSubject1 = new BehaviorSubject<Briefing[]>(data);
            this.briefings = currentUserSubject1.value;
        });
    }

    set briefingId(id) {
        this.dataService.getBriefing(+id).subscribe(data => {
            const currentUserSubject = new BehaviorSubject<Briefing>(data);
            this.briefing = currentUserSubject.value;

            this.dataService.getCategories(this.briefing).subscribe(data1 => {
                const currentUserSubject1 = new BehaviorSubject<Category[]>(data1);
                this.categories = currentUserSubject1.value;
            });
        });
    }

    get briefingId() {
        return this.briefing.id;
    }

    set categoryId(id) {
        this.dataService.getCategory(+id).subscribe(data => {
            const currentUserSubject = new BehaviorSubject<Category>(data);
            this.question.category = currentUserSubject.value;
        });
    }

    get categoryId() {
        return this.question.category.id;
    }

    createNewAnswer() {
        if (this.newAnswer.label && this.newAnswer.label.length > 0) {
            this.question.answers.push(this.newAnswer);
            this.newAnswer = new Answer();
        }
    }

    deleteAnswer(answer) {
        for (let i = 0; i < this.question.answers.length; i++) {
            if (this.question.answers[i] === answer) {
                this.question.answers.splice(i, 1);
            }
        }
    }


    ngOnInit() {
    }

    update() {
        if (this.question.label.length === 0) {
            this.errorMessage = 'Bitte überprüfen Sie Ihre Eingaben.';
        } else {
            this.dataService.updateQuestion(this.question).subscribe(() => {
                this.router.navigate(['/admin/questions/list/' + this.briefingId + '/' + this.categoryId]);
            });
        }
    }

}
