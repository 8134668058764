import {Component, OnInit} from '@angular/core';
import {DataService} from '../services/data.service';
import {Briefing} from '../models/briefing';
import {Category} from '../models/category';
import {ActivatedRoute, Router} from '@angular/router';
import {Question} from '../models/question';
import {Answer} from '../models/answer';
import {BehaviorSubject} from 'rxjs';

@Component({
    selector: 'app-admin-question-new',
    templateUrl: './admin-question-new.component.html',
    styleUrls: ['./admin-question-new.component.css', '../admin-question-edit/admin-question-edit.component.css']
})
export class AdminQuestionNewComponent implements OnInit {

    briefings: Briefing[] = [];

    briefing: Briefing;

    categories: Category[] = [];

    category: Category;

    errorMessage: string;
    message: string;

    question: Question;

    newAnswer: Answer = new Answer();

    constructor(private dataService: DataService, private router: Router, private route: ActivatedRoute) {

    }

    set briefingId(id) {
        this.dataService.getBriefing(+id).subscribe(data => {
            const currentUserSubject = new BehaviorSubject<Briefing>(data);
            this.briefing = currentUserSubject.value;
        });
        this.dataService.getCategories(this.briefing).subscribe(data => {
            const currentUserSubject1 = new BehaviorSubject<Category[]>(data);
            this.categories = currentUserSubject1.value;
        });
    }

    get briefingId() {
        return this.briefing.id;
    }

    set categoryId(id) {
        this.dataService.getCategory(+id).subscribe(data => {
            const currentUserSubject1 = new BehaviorSubject<Category>(data);
            this.question.category = currentUserSubject1.value;
        });
    }

    get categoryId() {
        return this.question.category.id;
    }

    createNewAnswer() {
        if (this.newAnswer.label && this.newAnswer.label.length > 0) {
            this.question.answers.push(this.newAnswer);
            this.newAnswer = new Answer();
        }
    }

    deleteAnswer(answer) {
        for (let i = 0; i < this.question.answers.length; i++) {
            if (this.question.answers[i] === answer) {
                this.question.answers.splice(i, 1);
            }
        }
    }

    ngOnInit() {
        const briefingId = this.route.snapshot.paramMap.get('briefingId');
        const categoryId = this.route.snapshot.paramMap.get('categoryId');

        this.dataService.getBriefings().subscribe(data => {
            const currentUserSubject1 = new BehaviorSubject<Briefing[]>(data);
            this.briefings = currentUserSubject1.value;
        });

        if (briefingId) {
            this.dataService.getBriefing(+briefingId).subscribe(data => {
                const currentUserSubject = new BehaviorSubject<Briefing>(data);
                this.briefing = currentUserSubject.value;

                this.dataService.getCategories(this.briefing).subscribe(data1 => {
                    const currentUserSubject1 = new BehaviorSubject<Category[]>(data1);
                    this.categories = currentUserSubject1.value;
                });
            });
        }
        if (categoryId) {
            this.dataService.getCategory(+categoryId).subscribe(data => {
                const currentUserSubject1 = new BehaviorSubject<Category>(data);
                this.category = currentUserSubject1.value;
                this.question = new Question(null, '', '', this.category, []);
            });

        }
    }

    create() {
        if (this.question.label.length === 0) {
            this.message = '';
            this.errorMessage = 'Bitte überprüfen Sie Ihre Eingaben.';
        } else if (this.question.answers.length === 0) {
            this.message = '';
            this.errorMessage = 'Bitte legen Sie mindestens eine Frage an.';
        } else {
            this.dataService.createQuestion(this.question).subscribe(data => {
                this.errorMessage = '';
                this.message = 'Die Frage wurde erfolgreich gespeichert.';

                // reset params
                this.ngOnInit();
            });
        }
    }

}
